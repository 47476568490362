<template>
    <img
     :src="image" 
     :srcset="imageSet"
     :width="width" 
     :height="height"
     :alt="alt"
     :loading="loading"
     class="img-fluid"
    />
</template>

<script>
export default {
    data() {
        return {
            image: this.placeholder(),
            imageSet: null,
            loading: null
        }
    },
    props: [
        'src',
        'srcset',
        'alt',
        'width',
        'height'
    ],
    methods: {
        placeholder() {
            return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${this.width || 512} ${this.height || 512}'%3E3C/svg%3E`
        }
    },
    mounted() {
        const obsever = new IntersectionObserver(entries => {
            const entry = entries[0];

            if(entry.isIntersecting) {
                this.image = this.src;
                this.imageSet = this.srcset;

                obsever.disconnect();
            }
        });

        if ('loading' in HTMLImageElement.prototype) {
            this.image = this.src;
            this.imageSet = this.srcset;
            this.loading = 'lazy';
        }
        else {

            if(this.srcset) {
                this.imageSet = `${this.placeholder()} 1x, ${this.placeholder()} 2x, , ${this.placeholder()} 3x`;
            }

            observer.observe(this.$el)
        }
    }
}
</script>