<template>
    <div class="produto-zoom" :class="{'desktop': desktop}">
        <div class="produto-zoom-desktop">
            <div class="mgb-15">
                <img
                 :src="album[0].md" 
                 id="zoom" 
                 :data-zoom-image="album[0].xl" 
                 class="img-fluid" 
                 width="560"
                 height="560"
                 loading="lazy"
                />
            </div>

            <div id="gallery-1">
                <a
                 v-for="(image, i) in album" 
                 :key="i" 
                 :href="image.xl" 
                 :class="{'active': i == 0}"
                 :data-image="image.md"
                 :data-zoom-image="image.xl"
                >
                    <img :src="image.xs" class="img-fluid" width="170" height="170" loading="lazy">
                </a>
            </div>

            <div class="d-none lightbox-helper">
                <a
                v-for="(image, i) in album" 
                :key="i" 
                :href="image.xl" 
                data-lightbox="produto-desktop"
                ></a>
            </div>
        </div>

        <div class="produto-zoom-mobile">
            <div 
                class="owl-carousel"
                data-nav="true"
                data-loop="false"
            >
                <a
                    v-for="(image, i) in album"
                    :key="i"
                    :href="image.xl"
                    data-lightbox="produto"
                >
                    <img 
                        :src="image.md" 
                        class="img-fluid"
                        loading="lazy"
                    >
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import './jquery.ez-plus.min'

export default {
    data() {
        return {
            desktop: false
        }
    },
    props: [
        'album'
    ],
    mounted() {
        const vm        = this;
        const mobile    = matchMedia('(max-width: 991px)')
        const desktop   = matchMedia('(min-width: 992px)')

        mobileCheker(mobile)
        mobile.addListener(mobileCheker)

        desktopCheker(desktop)
        desktop.addListener(desktopCheker)

        function desktopCheker(media) {
            if(media.matches) {
                vm.desktop = true
            }
        }

        function mobileCheker(media) {
            if(media.matches) {
                vm.desktop = false
            }
        }

        this.setZoom();
    },
    methods: {
        setZoom() {
            const $ZoomImage    = $(this.$el).find('#zoom')
            const $Galery       = $(this.$el).find('#gallery')
            const $LIGHTBOX     = $(this.$el).find('.lightbox-helper a');
            
            $ZoomImage.ezPlus({
                cursor: 'pointer',
                gallery: 'gallery-1',
                galleryActiveClass: "active",
                imageCrossfade: true,
                loadingIcon: "assets/images/loading.gif",
                tint: true,
                tintColour: '#000',
                tintOpacity: 0.65,
                responsive: true,
                zoomType: 'inner'
            });
            
            $ZoomImage.bind('click', function(e) {
                let ez = $ZoomImage.data('ezPlus');
                let current = ez.imageSrc;

                // ez.closeAll();

                $LIGHTBOX.each(function() {
                    let href = this.getAttribute('href');

                    if(href == current) {
                        this.click()
                    }
                });

                return false;
            })
        }
    }
}
</script>